import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ResultHeroMapService {
  markerSubject: Subject<any> = new Subject<any>();
  annuairesSubject: Subject<any> = new Subject<any>();
  mapAutoZoomSubject: Subject<any> = new Subject<any>();

  public markers: any[] = [];
  private _url: string;

  /**
   * constructor
   *
   * @param _eaHttpAbstract
   */
  constructor(private _eaHttpAbstract: EaHttpAbstract) {
    this._url = environment.url_api;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  emitPractice(practice) {
    this.markerSubject.next(practice);
  }
  emitZoom() {
    this.mapAutoZoomSubject.next("leave");
  }
  emitAnnuaire(annuaire) {
    this.annuairesSubject.next(annuaire);
  }

  /**
   * Get Practice detail
   *
   * @returns {Promise<any>}
   */
  getPracticeDetail(data): Observable<any> {
    return this._eaHttpAbstract.post("/public/search/hero_profile", {directory_reference: data.reference_directory})
        .pipe(
            map(response=>{
              return response;
            })
        );
  }
}
