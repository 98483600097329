import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Observable, Subject} from 'rxjs';
import {EaHttpAbstract} from "../../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import * as moment from "moment/moment";

/**
 * @description
 * @class
 */
@Injectable({
  providedIn: 'root'
})
export class DetailPracticeService {


  private _url: string;
  dataDetailSubject: Subject<any> = new Subject<any>();

  /**
   * Constructor
   *
   * @param _eaHttpAbstract
   */
  constructor(private _eaHttpAbstract: EaHttpAbstract) {

    this._url = environment.url_api;

  }


  /**
 * getAnnuaireSante
 *
 * return {Promise<any>}
 */
    
   getDirectory(Data): Observable<any> {
       const _data = {
           directory_reference: Data.reference,
           url: Data.url_public,
           nbr_of_days: Data.nbr_of_days,
           date_init: (moment().format('yyyy-MM-DD'))
       }
       return this._eaHttpAbstract.post("/public/directories/get_directory", _data)
           .pipe(
               map(response=>{
                   return response;
               })
           )
   }

    showPhoneNumber(reference: any) {
        return this._eaHttpAbstract.post("/public/directories/show_number", {directory_reference: reference})
            .pipe(
                map(response=>{
                    return response;
                })
            )
    }
}
