import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormsModule} from '@angular/forms';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import { SecurityService } from 'src/app/main/security/security.service';
import {STATUS_OK} from "../../app.constants";
import {CommonModule} from "@angular/common";
import {PlanningComponent} from "../../apps/planning/planning.component";
import {SharedModule} from "../../shared/shared.module";
import {MatToolbarModule} from "@angular/material/toolbar";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {MatIconModule} from "@angular/material/icon";
import {SignInComponent} from "../security/sign-in/google/sign-in.component";
import {AuthenticationComponent} from "./authentication/authentication.component";
import {SammaryComponent} from "./sammary/sammary.component";
import {ChoiceComponent} from "./choice/choice.component";
import {ConfirmationComponent} from "./confirmation/confirmation.component";
import {
  FacebookLoginProvider, GoogleLoginProvider,
  SocialAuthService, SocialAuthServiceConfig, SocialLoginModule,
} from "@abacritt/angularx-social-login";
import {VerificationComponent} from "./verification/verification.component";
import {BookingService} from "./booking.service";
import {Router} from "@angular/router";
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PlanningComponent,
    SharedModule,
    MatStepperModule,
    MatToolbarModule,
    NgxIntlTelInputModule,
    MatIconModule,
    FormsModule,
    SignInComponent,
    AuthenticationComponent,
    SammaryComponent,
    ChoiceComponent,
    ConfirmationComponent,
    SocialLoginModule,
    VerificationComponent
  ],
  providers : [
     SecurityService,
     SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider
            ('239415642847-ui5ojj2mqe1s4n310vvgompi3v1sv73l.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('426812791556548')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ]
})
export class BookingComponent implements OnInit {
  connected : boolean = false;
  appointment = null;
  authenticationForm : FormGroup;
  step = 1;
  isAuthenticated = false;

  @ViewChild('horizontalStepper')public myStepper : MatStepper;
  @ViewChild('horizontalStepper')public myStepper_ : CdkStepper;
  constructor(private _securityService : SecurityService,
              private _bookingService : BookingService,
              private _router: Router
    ) { }

  ngOnInit(): void {
    const appointment =  localStorage.getItem('appointement');
    if(appointment){
      this.appointment = JSON.parse(appointment);
      this.verifyConnected();
      this._bookingService.appointmentSelected.next(this.appointment)
    }else{
      this._router.navigate(['/']);
    }
  }

  public onStepChange(event: any): void {
    setTimeout(() => {
      if (this.myStepper.selectedIndex != 0) {
        const isConnected = this._securityService.isConnected;
        if (isConnected == false) {
          this.myStepper.selectedIndex = 0;
          this.myStepper._steps.forEach(step => {
            if (step.label != "Authentification") {
              step.interacted = false
            } 
          });
        }
      }
    }, 1);
  }
  public addItem(newItem) {
    this.authenticationForm = newItem;
  }

  public oneStepBack() {
    this.myStepper_.previous();
  }
  public verifyConnected() {
    this._securityService.authenticated()
      .subscribe((res) => {
        if (res.status === STATUS_OK) {
          // this._procheService.getMesProches("").subscribe();
          if (this.myStepper.selectedIndex == 0) {
            this.myStepper._steps.first.editable = false;
            this.myStepper.selectedIndex = 1;
          }
          this.isAuthenticated = true;
        }
      });
  }
}