<div class="step-content"  *ngIf="practice && proche">
<div class="message-confirmation" >
  TicTacSanté vous confirme votre rendez-vous.
</div>
<div class="booking-summary" style="padding-bottom: 0;">
<div class="title-col">
    <h5 class="mt-0">Patient</h5>
</div>
</div>
<mat-card class="doctor-widget doctor-dashboard" >
    <div class="doc-info-left">
        <div class="doctor-img">
            <a class="doctor-img-profile" href="/">
                <img
                        [src]="proche.picture ? 'https://cdn.tictacsante.com/assets/images/user/' + proche.picture : 'https://cdn.tictacsante.com/assets/images/doc/Default.png'"
                        class="img-fluid img-circle" alt="User Image">
            </a>
        </div>
        <div class="doc-info-cont">
  
            <h4 class="doc-name m-0"><a href="/">{{proche.first_name+" "+proche.last_name }}</a></h4>
            <div class="toggle-icon-doctor">
                <span class="doc-department">
                    <span class="speciality-name">
                        {{proche.category}}
                    </span>
                </span>
            </div>

            <div class="doc-info custom-info">
                <div class="doc-location">
                    <p>
                        <mat-icon>location_on</mat-icon> {{proche?.city}}
                    </p>
                </div>
            </div>
        </div>
    </div>
  </mat-card>
  <div class="booking-summary"  style="padding-bottom: 0;">
  <div class="title-col">
    <h5 class="mt-0">Practicien</h5>
</div>
</div>
<mat-card class="doctor-widget doctor-dashboard" >
  <div class="doc-info-left">
      <div class="doctor-img">
        <a class="doctor-img-profile" href="/">
            <img 
            [src]="'https://cdn.tictacsante.com/assets/images/doc/' + dataPractice?.picture"
            class="img-fluid img-circle" alt="{{dataPractice.title}}"
                 (error)="handleImageError($event)"
            ><!--[src]="imageUtils.getPhoto(dataPractice?.photo)" -->
        </a>
      </div>
      <div class="doc-info-cont">
          <h4 class="doc-name m-0"><a href="/">{{dataPractice.title}}</a></h4>
          <div class="toggle-icon-doctor">
              <span class="doc-department">
                  <span class="speciality-img">
                      <img src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg" class="img-fluid"
                          alt="Speciality">
                  </span>
                  <span class="speciality-name">
                      {{dataPractice.specialty}}
                  </span>
              </span>
          </div>
          <div class="rating">
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <span class="d-inline-block average-rating">(0)</span>
          </div>
          <div class="doc-info custom-info">
              <div class="doc-location">
                  <p>
                      <mat-icon>location_on</mat-icon> {{dataPractice?.city}}
                  </p>
              </div>
          </div>
      </div>
  </div>
</mat-card>
<div class="booking-summary">
  <div class="title-col">
      <h5 class="mt-0">Détails du rendez-vous</h5>
  </div>
  <div class="summary-col">
      <mat-card class="summary-col-inner" *ngIf="timeSlot">
            <div>Consultation <span>Au cabinet</span></div>
            <div>Date <span>{{timeSlot?.date | date}}</span></div>
            <div>Heure<span>{{time?.hour_text}}</span></div>
            <div>Motif<span>{{motif?.type_name | titlecase}}</span></div>
            <div *ngIf="comment">Commentaire <span>{{comment}}</span></div>
      </mat-card>
  </div>
</div>
<div>
    <div class="flex justify-between items-center mt-3 p-2">
        <button (click)="rendezVous()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button" (click)="confirmerRdv()">
            <span class="uppercase mr-1">Mes rendez-vous</span>
            <mat-icon>navigate_next</mat-icon>
        </button>
    </div>
</div>
</div>