import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event:any) => {
            if(event?.body?.maintenance){
                this.router.navigate(['/maintenance']);
            }
            return event;
          }),
          catchError((error: any) => {
              if (error.status === 503 || error.status === 504 || error.status === 0) {
                  this.router.navigate(['/maintenance']);
              }
              // else if (error.status === 401) {
              //     // Handle unauthorized error
              //     // For example, redirect to login page
              //     this.router.navigate(['/login']);
              // }
              const started = Date.now();
              const elapsed = Date.now() - started;
              console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
              return throwError(error);
          })
        );
    }
}