<div class="flex justify-center items-center min-h-full">
    <div class="w-full md:w-1/3 text-center">
        <img src="assets/images/logos/logo1.svg" alt="TicTacSante" class="w-96 mb-6 mx-auto" />
        <div class="bg-blue-600 p-6 rounded-t-lg text-center">
            <mat-icon class="mat-icon-xlg w-10 h-10 text-white mx-auto">build</mat-icon>
            <h1 class="text-3xl font-bold text-white text-center mt-4">Maintenance</h1>
        </div>
        <div class="bg-white rounded-b-lg shadow-lg p-6">
            <div class="text-center">
                <p class="text-gray-700">Notre site web est actuellement en maintenance. Merci de votre patience.</p>
                <a href="/" class="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Go to Home</a>
            </div>
        </div>
    </div>
</div>
