<div  *ngIf="!isConnected">
    <a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
        <mat-icon class="mat-icon-sm">person</mat-icon>
        <div class="flex flex-row items-center justify-center">
            <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">S'identifier</span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </div>
    </a>
    <mat-menu  #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu" >
        <span (mouseleave)="userMenuTrigger.closeMenu()">
            <a mat-menu-item routerLink="/sign-in">
                <span>Connexion</span> 
            </a>
            <a mat-menu-item routerLink="/sign-up"> 
                <span>Créer un compte</span> 
            </a>
        </span>
    </mat-menu>  
</div>

<div *ngIf="isConnected && user">
    <a  mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger"  >
        <mat-icon class="mat-icon-sm">person</mat-icon>
        <!-- Tarik S. -->
        <div class="flex flex-row items-center justify-center">
            <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase"> {{user.username}} </span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </div>
    </a>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu" >
        <span (mouseleave)="userMenuTrigger.closeMenu()">
            <div fxLayout="row" fxLayoutAlign="start center" class="user-info">                   
                <img [src]="user?.avatar ? PATH_AVATAR + user?.avatar : 'assets/images/Default.png'" alt="user-image" width="50">
                <p class="m-0"> {{user.first_name+' '+user.last_name}} <br> <small><i>Patient</i></small></p>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/patient-area"> 
                <mat-icon class="mat-icon-sm">add_circle</mat-icon>
                <span>Mon Compte</span> 
            </a>
            <a mat-menu-item routerLink="/patient-area/rendez-vous"> 
                <mat-icon class="mat-icon-sm">add_circle</mat-icon>
                <span>Mes Rendez-Vous</span> 
            </a>
            <div class="divider"></div>
            <a mat-menu-item (click)="logout()"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>Déconnexion</span>
            </a>
        </span>
    </mat-menu>  
</div>