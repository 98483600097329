<mat-toolbar id="top-toolbar" class="top-toolbar">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxHide="false" fxHide.gt-sm>
        <button mat-button (click)="sidenavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
      <app-social-icons fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-md fxShow.gt-sm></app-social-icons>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="">
      <!-- <app-currency></app-currency> -->
      <app-lang></app-lang>
      <app-user-menu></app-user-menu>
    </div>
  </div>
</mat-toolbar>
<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="logo" routerLink="/" ><app-logo></app-logo></a>
    <div *ngIf="settings?.menu"
         fxShow="false" fxShow.gt-sm class="horizontal-menu">
      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <a routerLink="/account/favorites" mat-icon-button matTooltip="Favoris" fxShow="false" fxShow.gt-md>
        <mat-icon [matBadge]="appService?.Data?.favorites?.length"
                  [matBadgeHidden]="appService?.Data?.favorites?.length == 0"
                  matBadgeSize="small"
                  matBadgeColor="warn">favorite_border</mat-icon>
      </a>
      <a *ngIf="!environment.production" routerLink="/compare" mat-icon-button matTooltip="Compare list" fxShow="false" fxShow.gt-md class="mr-3 ml-2">
        <mat-icon [matBadge]="appService.Data.compareList.length"
                  [matBadgeHidden]="appService.Data.compareList.length == 0"
                  matBadgeSize="small"
                  matBadgeColor="warn">compare_arrows</mat-icon>
      </a>
      <a routerLink="/" mat-mini-fab color="orange" fxHide="false" fxHide.gt-sm class="orange">
        <mat-icon>medical_services</mat-icon>
      </a>
      <a routerLink="/" mat-raised-button fxShow="false" fxShow.gt-sm class="uppercase orange ml-3 mr-2" *ngIf="!environment.production">
        Urgence ?
      </a>
      <a routerLink="/" mat-mini-fab color="primary" fxHide="false" fxHide.gt-sm><mat-icon>add</mat-icon></a>
      <a href="https://practices.tictacsante.com/"
         target="_blank" mat-raised-button
         color="primary"
         fxShow="false"
         fxShow.gt-sm class="uppercase"
         *ngIf="isPatient">
        Vous êtes praticien ?
      </a>
    </div>
  </div>
  <button *ngIf="!hide" class="header-cas country" (click)="clickCountry()" [style.background-image]="'url(' + currentFlagUrl() + ')'"
          [style.background-size]="'18px 13.5px'" [style.background-repeat]="'no-repeat'" [style.background-position]="'center'"
  >
  </button>

  <!-- Dropdown menu -->
  <div *ngIf="open" tabindex="-1" class="country absolute top-24 z-10 bg-white rounded divide-y divide-gray-100 shadow-md dark:bg-gray-700 dark:divide-gray-600">
    <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 country" aria-divledby="dropdownToggleButton">
      <li>
        <div class="text-base country">Europe</div>
        <div class="flex p-2 country">
          <div class="grid grid-cols-3 gap-4 w-full country">
            <span class="rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center py-1 px-2 country" (click)="changeLocation('Fr')">
              <img class="mr-1" height="13.5" width="18" src="assets/images/flags/fr.svg" alt="fr">France</span>
          </div>
        </div>
      </li>
      <li>
        <div class="text-base border-t border-black pt-2 country">Afrique</div>
        <div class="flex p-2 country">
          <div class="grid grid-cols-3 gap-4 w-full country">
          <span class="rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center py-1 px-2 country" (click)="changeLocation('Ma')">
            <img class="mr-1" height="13.5" width="18" src="assets/images/flags/ma.svg" alt="ma">Maroc</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</mat-toolbar>
