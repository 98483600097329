import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
@Injectable({
    providedIn: 'root'
})
export class AccountService
{
    account: any;
    timelineOnChanged: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;
    accountOnChanged: BehaviorSubject<any>;
    adressOnChanged:BehaviorSubject<any>;
    OnAccountChanged:BehaviorSubject<any>;
    // Private

    /**
     * Constructor
     *
     * @param _eaHttpAbstract
     * @param _utilsService
     */
    constructor(
        private _eaHttpAbstract: EaHttpAbstract,
        )
    {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject({});
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
        this.accountOnChanged = new BehaviorSubject({});
        this.adressOnChanged = new BehaviorSubject({});
        this.OnAccountChanged = new BehaviorSubject({});
    }

    /**
     * Get infos Profile
     */
     getInfosAccount(): Observable<any>
     {
         return this._eaHttpAbstract.post("/patient_area/get_details", {})
             .pipe(
                 map((response: any)=>{
                     return response;
                 })
             );
     }

    updateAddress(DataAddress) {
        const _data = {
            address: DataAddress.adresse,
            additional_address: DataAddress.complementAdresse,
            zip_code: DataAddress.zip_code,
            country: DataAddress.pays,
            city: DataAddress.ville
        }
        return this._eaHttpAbstract.post("/patient_area/update_address", _data)
            .pipe(
                map((response: any)=>{
                    return response;
                })
            );
    }

    /**
     * update account security
     *
     * @param DataSecurity: Array
     * @returns {Promise<any>}
     */
    updateAccountSecurity(DataSecurity): Observable<any> {
        const data = {
            old_password: DataSecurity.oldPassword,
            new_password: DataSecurity.newPassword
        }
        return this._eaHttpAbstract.put("/accounts/update_password", data)
            .pipe(
                map(response => {
                    return response;
                })
            )
    }
    /**
     * get Data user
     *
     * @returns {Promise<any>}
     */
    disableAccount(): Observable<any> {
        return this._eaHttpAbstract.put("/accounts/disable", {})
            .pipe(
                map(response => {
                    return response;
                })
            )
    }
     /**
     * get Data user 
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    VerifyEmail(Data): Observable<any> {
         /**
         * Params
         */
         return this._eaHttpAbstract.post('/accounts/change_email', {email: Data.email})
             .pipe(
                 map(response=>{
                     return response;
                 })
             );
    }
    /**
     * get Data user
     *
     * @returns {Promise<any>}
     * @param GSM
     */
    VerifyGSM(GSM): Observable<any> {
        return this._eaHttpAbstract.post('/accounts/change_gsm', {gsm: GSM})
            .pipe(
                map(response=>{
                    return response;
                })
            );
    }
    validateEmail(validation_code): Observable<any> {
        return this._eaHttpAbstract.post('/accounts/validation_code_email', {validation_code: validation_code})
            .pipe(
                map(response=>{
                    return response;
                })
            );
    }
    validateGSM(validation_code): Observable<any> {
        return this._eaHttpAbstract.post('/accounts/validation_code_gsm', {validation_code: validation_code})
            .pipe(
                map(response=>{
                    return response;
                })
            );
    }
}