import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoteurRechercheService implements Resolve<any>{


  paramsSubject : Subject<any> = new Subject<any>();
  paramObservable(): Observable<any> {
    return this.paramsSubject.asObservable();
}
  
  dataSubject : Subject<any> = new Subject<any>();
  citySubject : Subject<any> = new Subject<any>();
  specialitySubject : Subject<any> = new Subject<any>();

  private _url: string; 

  /**
     * constructor
     *
     * @param {HttpClient} _httpClient
     */
  constructor(private _httpClient: HttpClient) { 
    this._url = environment.url_api;
  }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([

            ]).then(
                ([]) => {
                  
                  resolve([]);
                },
                reject
            );
        });
    }
    emitParams(params){
        this.paramsSubject.next(params);
    }
    emitData(data){
      this.dataSubject.next(data);
    }
    searchCity(paramCity){
       this.citySubject.next(paramCity);
    }
    searchSpeciality(paramSpeciality){
      this.specialitySubject.next(paramSpeciality);
    }
}
