import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '../booking.service';
import {DEFAULT_PHOTO} from "../../../constants/constants";
import {ImageUtils} from "../../../../@ea/helpers/image-utils";
import {SharedModule} from "../../../shared/shared.module";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  standalone:true,
  imports: [SharedModule]
})
export class ConfirmationComponent implements OnInit {

  appointement: any;
  practice: any;
  timeSlot: any;
  time: any;
  proche : any;
  motif: any;
  dataPractice: any;
  comment = "";
  constructor(public router: Router,private _bookingService : BookingService,private bookingService : BookingService) { }

  ngOnInit(): void {
    // appointment
    this._bookingService.appointmentSelected.subscribe(val=>{
      if(val){
        this.appointement = val;
        this.practice = this.appointement?.practice;
        this.timeSlot = this.appointement?.timeSlot;
        this.time = this.appointement?.time;
        this.motif = this.appointement?.motif;
        this.dataPractice = {
          title:   this.practice?.title,
          specialty: this.practice?.speciality,
          city: this.practice?.city,
          photo: this.practice?.picture
        }
      }
    })
    //proche
    this.bookingService.procheSelectedSubject.subscribe((data)=>{
      this.proche = data.proche;
      this.comment = data.comment;
    });
  }
  rendezVous(){
    localStorage.removeItem('appointement');
    this.router.navigate(['/patient-area/rendez-vous']);
  }
  defaultAvatarTTSUrl = DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
  imageUtils = new ImageUtils();
}
