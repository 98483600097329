<div class="calendar-wrapper">
    <div class="calendar-week pr-2.5">
        <button class="nav-button-left" mat-icon-button type="button" (click)="previousDays()"
                [ngStyle]="{cursor: (isToday) ? 'default' : 'pointer'}" [disabled]="isToday || errorOccurred">
            <mat-icon [ngStyle]="{color: (isToday || errorOccurred) ? '#c1d7d7' : ''}">chevron_left</mat-icon>
        </button>
        <ul class="">
            <li *ngFor="let TimeSlot of TimesSlot" class="day flex row-auto justify-center items-center">
                <div class="grid py-1 mr-1">
                    <div>{{ TimeSlot.day_name }}</div>
                    <div>{{ TimeSlot.month }}</div>
                </div>
                <div class="rounded-full bg-white text-blue-600 space">{{TimeSlot.day}}</div>
                <span class="day-counter">{{ TimeSlot.count_slots }}</span>
            </li>
        </ul>
        <button class="nav-button-right" (click)="nextDays()" [disabled]="errorOccurred"  mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
    <div class="withScroll container-scroll" data-height="330">
        <mat-spinner *ngIf="spin" color="warn" style="margin:0 auto; margin-top: 100px;"></mat-spinner>
        <div class="calendar-hours">
            <ul>
                <li *ngFor="let TimeSlot of TimesSlot">
                    <ul>
                        <li *ngFor="let time of TimeSlot.times" (click)="startProcessRdv(time, TimeSlot)">
                            <span  >{{ time?.hour_text }}</span>
                        </li>
                    </ul>
                    <ul class="flex justify-around" *ngIf="TimeSlot?.times?.length <= 0">
                        <li class="empty">
                            <span>
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
            <div *ngIf="errorOccurred && !spin" class="flex items-center w-full cursor-pointer" role="alert">
                <div class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                    <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div class="ms-3 text-sm font-medium">
                        Aucune disponibilité en ligne.
                    </div>
                </div>
            </div>
            <div *ngIf="(emptyPlanningTimes || nextDateApp) && !spin" class="flex items-center w-full cursor-pointer" (click)="goToNextDateApp()">
                <div class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                    <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div class="ms-3 text-sm font-medium">
                        Prochain RDV le <strong>{{nextDateApp | date}}</strong>
                    </div>
                    <mat-icon class="icon-style">keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <div *ngIf="errorNewPatient && !spin" class="flex items-center p-4 mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="ms-3 text-sm font-medium">
                    Ce praticien réserve la prise de rendez-vous en ligne aux patients déjà suivis.
                </div>
            </div>
            <div *ngIf="emptyPlanning && !spin" class="flex items-center p-4 my-auto mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="ms-3 text-sm font-medium">
                    Pas de rendez-vous en ligne disponible pour ce praticien. Veuillez appeler le
                    <span class="font-bold text-gray-500">{{ Practice?.phone }}</span> pour prendre un rendez-vous.
                </div>
            </div>
        </div>
    </div>
</div>
