import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Practice } from 'src/app/models/practice.model';

import { environment } from 'src/environments/environment';
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import {STATUS_OK} from "../../app.constants";
/**
 * @description
 * @class
 */
@Injectable()
export class IndexService{

    public new_practices: Practice[];
    private url_api: string;
    Data: any   = {};
    net_infos : any;
    custom_network : any;
    network;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _eaHttpAbstract
     */
    constructor(  private _httpClient: HttpClient,
                  private _eaHttpAbstract: EaHttpAbstract
    ) {
        // this.network =  localStorage.getItem('network_data');
        // this.custom_network = localStorage.getItem("chosenLocation");
        // this.net_infos = JSON.parse(this.custom_network ? this.custom_network : this.network);
        // this.Data.country      = "fr";
        // this.url_api = environment.url_api;
    }

    /**
     * Get New Practices
     *
     * @returns {Promise<any>}
     */
    getNewPractices(data): Observable<any> {
        /**
        * Params
        */
        return this._eaHttpAbstract.post("/public/practices/new_practices", data)
            .pipe(
                map((response: any) => {
                    if (response.status === STATUS_OK) {
                        this.new_practices = response.data;
                    }
                    return response;
                })
            )
    }

}
