import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {SecurityService} from "../../../security/security.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {STATUS_ERROR, STATUS_OK} from "../../../../app.constants";
import {SharedModule} from "../../../../shared/shared.module";

@Component({
  selector: 'app-dialog-phone-validation-code',
  templateUrl: './dialog-phone-validation-code.component.html',
  styleUrls: ['./dialog-phone-validation-code.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ],
  providers: [SecurityService]
})
export class DialogPhoneValidationCodeComponent implements OnInit {
  validateForm: FormGroup;
  isLoading: boolean = false;
  errorMessage: string = "";
  constructor(
    private dialogRef: MatDialogRef<DialogPhoneValidationCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public _securityService: SecurityService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.validateForm = this._formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });
  }
  valider() {
    this.isLoading = true;
    if (this.validateForm.valid) {
      this._securityService.validateInscription(this.validateForm.value).subscribe((res) => {
        if(res.status === STATUS_ERROR){
          this.errorMessage = "Code incorrect";
          this.snackBar.open(this.errorMessage, '×', {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['red-snackbar'],
          });
        }else if(res.status === STATUS_OK){
          this.data.validCode = true;
          this.dialogRef.close(this.data);
        }
        this.isLoading = false;
      });
    }
  }
}
