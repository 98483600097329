import {AbstractControl, ValidationErrors} from "@angular/forms";

export function inputValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    const minLengthValid = password && password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!minLengthValid) {
        return { minlength: true };
    }

    if (!hasUppercase) {
        return { uppercase: true };
    }

    if (!hasSpecialChar) {
        return { specialChar: true };
    }

    return null;
}

export function alphabeticValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const isAlphabetic = /^[A-Za-zÀ-ÿ]+$/.test(value);

    return isAlphabetic ? null : {alphabetic: true};
}

export function usernameOrEmailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@/;  //start with @
    const domainPattern = /.*\.(com|fr|net|org|[a-z]{2,})$/;

    if (emailPattern.test(value) || domainPattern.test(value)) {
        return validateEmail(control);
    }
    return validateUsername(control);
}

function validateEmail(control: AbstractControl): ValidationErrors | null {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(control.value) ? null : { email: true };
}

function validateUsername(control: AbstractControl): ValidationErrors | null {
    const username = control.value;
    return username.length >= 6 ? null : { minlength: true };
}