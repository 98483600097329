<div class="card" style="padding: 20px;">
  <h1 mat-dialog-title class="section-title text-center" style="font-size: 2em; color: #0099da;">{{ data.title }}</h1>
  <div class="text-base font-light text-center my-2">
    Vous allez recevoir dans quelques instants un SMS
    <br />
  </div>
  <div class="flex justify-center items-center ml-1">
    <div class="flex items-center mr-2">
      <mat-icon matPrefix *ngIf="isLoading" class="mr-1 text-muted animate-spin">refresh</mat-icon>
    </div>
  </div>
  <br />
  <!-- Actions -->
  <div class="mt-2 mx-auto flex justify-around">
    <form [formGroup]="validateForm" [style.fontSize.px]="12">
      <!-- Code -->
      <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-label>Code</mat-label>
        <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
        <input matInput formControlName="code" maxlength="6" required>
        <mat-error *ngIf="validateForm.controls.code.errors?.required">Code invalide</mat-error>
      </mat-form-field>
      <button [disabled]="" style="width:100%" mat-raised-button [color]="'primary'" class="py-2" (click)="valider()">
        Valider
      </button>
    </form>
  </div>
</div>
