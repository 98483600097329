<mat-sidenav-container class="flex">

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider border-b-2 border-gray-200"></div>

        <div perfectScrollbar class="vertical-menu-wrapper">
            <app-vertical-menu [menuParentId]="0"></app-vertical-menu>
        </div>

        <div class="divider border-b-2 border-gray-200"></div>
        <app-social-icons [iconSize]="'xlg'" [iconColor]="'primary'" class="flex justify-center"></app-social-icons>

    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper flex flex-col justify-start">

        <header [ngClass]="'toolbar-'+settings.toolbar"
                [class.has-bg-image]="settings.headerBgImage"
                [class.has-bg-video]="settings.headerBgVideo"
                [class.main-toolbar-fixed]="settings.mainToolbarFixed">

            <app-toolbar1 *ngIf="settings.toolbar == 1" (onMenuIconClick)="sidenav.toggle()"></app-toolbar1>
            <app-toolbar2 *ngIf="settings.toolbar == 2" (onMenuIconClick)="sidenav.toggle()"></app-toolbar2>

        </header>

        <main class="content-offset-to-top">
            <router-outlet></router-outlet>
        </main>

        <div class="sticky-footer-helper flex-grow"></div>

        <app-footer></app-footer>

        <!--<div class="options-icon flex justify-center items-center" (click)="appointmentIndex.toggle()">
            <mat-icon color="primary">medical_services</mat-icon>
        </div>-->

        <div *ngIf="showBackToTop" class="back-to-top flex justify-center items-center" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>

    </mat-sidenav-content>

    <mat-sidenav #appointmentIndex position="end" class="options" autoFocus="false">
        Hello
    </mat-sidenav>
    <!--<mat-sidenav #appointmentIndex position="end" class="options" autoFocus="false">
        <div class="op-header flex justify-between items-center mat-elevation-z1">
            <h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
                Rendez-vous en cours de réservation
            </h2>
            <button mat-icon-button (click)="appointmentIndex.toggle()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div perfectScrollbar>
            <div class="relative flex-1 px-4 sm:px-6" *ngIf="dataPractice">
                <div class="sm:flex-row flex-auto sm:items-center min-w-0 my-4 sm:my-4">
                    <div class="flex flex-auto items-center min-w-0">
                        <div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                            <div class="flex-shrink-0 group block">
                                <div class="flex items-center">
                                    <img class="inline-block flex-shrink-0 size-[62px] rounded-full" [src]="'https://cdn.tictacsante.com/assets/images/doc/' + dataPractice?.picture" alt="Image Description">
                                </div>
                            </div>
                        </div>
                        <div class="ms-3">
                            <h3 class="font-semibold text-gray-800 dark:text-white">{{dataPractice.title}}</h3>
                            <p class="text-sm font-medium text-gray-400">{{dataPractice?.specialty}}</p>
                        </div>
                    </div>
                    &lt;!&ndash; Actions &ndash;&gt;
                    <div class="flex items-center mt-4 sm:ml-2 space-x-3">
                        <button mat-flat-button color="primary" class="m-auto" (click)="appointmentIndex.toggle()" routerLink="/booking">
                            <span>Continue la réservation</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="relative flex-1 px-4 sm:px-6 alert-message-style-warning m-4&#45;&#45;" *ngIf="!dataPractice">
                Aucune réservation trouvé
            </div>
        </div>
    </mat-sidenav>-->
    <!--<mat-sidenav #options position="end" class="options" autoFocus="false">
        <div class="op-header flex justify-between items-center mat-elevation-z1">
            <h2>Settings</h2>
            <button mat-icon-button (click)="options.toggle()" color="accent">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div perfectScrollbar>
            <div class="control">
                <h4>Layout</h4>
                <div class="flex justify-between items-center">
                    <span>Sticky Menu Toolbar</span>
                    <mat-slide-toggle [checked]="settings.stickyMenuToolbar" (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar" labelPosition="before"></mat-slide-toggle>
                </div>
                <div class="flex justify-between items-center">
                    <span>RTL</span>
                    <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl" labelPosition="before"></mat-slide-toggle>
                </div>
            </div>

            <div class="control">
                <h4>Choose toolbar type</h4>
                <mat-radio-group [(ngModel)]="toolbarTypeOption" (change)="chooseToolbarType()">
                    <mat-radio-button *ngFor="let toolbarType of toolbarTypes" [value]="toolbarType">toolbar-{{toolbarType}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="control">
                <h4>Choose header type</h4>
                <mat-radio-group [(ngModel)]="headerTypeOption" (change)="chooseHeaderType()">
                    <mat-radio-button *ngFor="let headerType of headerTypes" [value]="headerType">{{headerType}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="control">
                <h4>Choose theme skin</h4>
                <div class="flex justify-between items-center">
                    <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
                    <div class="skin-primary green" (click)="changeTheme('green')"></div>
                    <div class="skin-primary red" (click)="changeTheme('red')"></div>
                    <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
                    <div class="skin-primary purple" (click)="changeTheme('purple')"></div>
                    <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
                    <div class="skin-primary orange-dark" (click)="changeTheme('orange-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                </div>
            </div>

            <div class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants" [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick" (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On Button Click</mat-slide-toggle>
            </div>
        </div>
    </mat-sidenav>-->

</mat-sidenav-container>