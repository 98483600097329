<div class="text-center">
    <h2 class="title"><strong>Êtes-vous nouveau patient ?</strong></h2>
</div>
<span class="alert alert-danger col-12" style="color:red;" *ngIf="errorMessage">
</span>
<span class="alert alert-success col-12" style="color:green;" *ngIf="successMessage">
</span>

<form [formGroup]="signUpForm" [style.fontSize.px]="12">
    <!-- Civility -->
    <div class="text-center py-3">
        <mat-button-toggle-group class="w-full" name="fontStyle" aria-label="Font Style" formControlName="genre">
            <mat-button-toggle class="w-full" [checked]="'checked'" value="Mme">Mme</mat-button-toggle>
            <mat-button-toggle class="w-full" value="Mlle">Mlle</mat-button-toggle>
            <mat-button-toggle class="w-full" value="Mr">Mr</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="grid">
        <!-- first name -->
        <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-icon matPrefix class="mr-1 text-muted">person</mat-icon>
            <mat-label>Prénom</mat-label>
            <input style="height: 15px;" matInput placeholder="Prenom" formControlName="firstname" required>
            <mat-error *ngIf="signUpForm.controls.lastname.errors?.required">Prénom est obligatoire</mat-error>
            <!-- last name -->
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-icon matPrefix class="mr-1 text-muted">person</mat-icon>
            <mat-label>Nom</mat-label>
            <input style="height: 15px;" matInput placeholder="Nom" formControlName="lastname" required>
            <mat-error *ngIf="signUpForm.controls.firstname.errors?.required">Nom est obligatoire</mat-error>
        </mat-form-field>
    </div>
    <!-- Email -->
    <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-icon matPrefix class="mr-1 text-muted">email</mat-icon>
        <mat-label>Email</mat-label>
        <input matInput placeholder="Adresse Email" formControlName="email" required>
        <mat-error *ngIf="signUpForm.controls.email.errors?.required">Email est obligatoire</mat-error>
        <mat-error *ngIf="signUpForm.controls.email.hasError('email')">Adresse e-mail incorrecte</mat-error>
    </mat-form-field>
    <!-- Mobile -->
    <div class="w-100 mt-1 wrapper" appearance="outline" style="margin-bottom: 18px;">
        <ngx-intl-tel-input style="height: 45px; width: 600px;" [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                            [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [maxLength]="15"
                            [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
                            formControlName="mobile" name="mobile">
        </ngx-intl-tel-input>
        <mat-error *ngIf="signUpForm.controls.mobile.errors?.required && !signUpForm.controls.email.errors?.required" class="ml-3">
            Un numéro de téléphone est obligatoire.
        </mat-error>
        <mat-error *ngIf="signUpForm.controls.mobile.invalid && !signUpForm.controls.mobile.errors?.required" class="ml-3">
            Le numéro de téléphone est mal formulé.
        </mat-error>
    </div>
    <!-- Password -->
    <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
        <mat-label>Mot de passe</mat-label>
        <input style="height: 15px;" matInput placeholder="Mot de passe" formControlName="password" type="password"
               minlength="6" required [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="signUpForm.controls.password.errors?.required">Veuillez saisir un mot de passe</mat-error>
        <mat-error *ngIf="signUpForm.controls.password.hasError('minlength')">Le mot de passe n'est pas assez long,
            minimum de 6 caractères</mat-error>
        <button mat-icon-button matSuffix (click)="hide = !hide" type="button" class="text-muted">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <!-- license -->
    <div class="mt-2 text-left">
        <div class="h-12 text-left">
            <mat-slide-toggle id="terms" formControlName="cgu" [color]="'primary'" (change)="isAcceptedTerms = !isAcceptedTerms">
                <div class="w-full whitespace-normal ml-2">
                    J’accepte les
                    <a href="https://about.tictacsante.com/conditions-generales-utilisation">
                        <strong style="color: #0f7173" class="hover:underline">
                            conditions générales d'utilisation
                        </strong>
                    </a>
                    du service TicTacSanté et je déclare être majeur. (*)
                </div>
            </mat-slide-toggle>
        </div>
    </div>
    <!-- Actions -->
    <div class="text-center mt-2">
        <button [disabled]="!isAcceptedTerms" mat-raised-button [color]="'primary'" class="py-2 w-full" (click)="validateInscriptionBooking()">
            Valider
        </button>
    </div>
    <div class="access-panel__social">
        <div class="access-panel__social-divider">
            <div class="access-panel__social-divider-line"></div>
            <span class="access-panel__social-divider-text">ou connectez-vous en un clic</span>
            <div class="access-panel__social-divider-line"></div>
        </div>
        <div class="access-panel__social-buttons access-panel__social-buttons--many">
            <button  (click)="signInWithFB()"
                class="access-panel__social-button access-panel__social-button-facebook bui-button bui-button--secondary nw-social-btn-facebook"
                data-ga-action="click" data-ga-label="Sign in with facebook" style="width: 50%;">
                <div class="access-panel__social-button-content">
                    <div class="access-panel__social-button-image">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" aria-labelledby="facebook-label" role="img">
                            <path
                                    d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.323-.593 1.323-1.325V1.325C24 .593 23.407 0 22.675 0z">
                            </path>
                        </svg>
                    </div>
                    <div class="access-panel__social-button-text">
                        <div
                                class="access-panel__social-button-text-span access-panel__social-button-text-span--long">
                            S'identifier via Facebook
                        </div>
                        <div id="facebook-label"
                             class="access-panel__social-button-text-span access-panel__social-button-text-span--short">
                            Facebook
                        </div>
                    </div>
                </div>
            </button>
            <button (click)="googleSignin()"
               class="access-panel__social-button access-panel__social-button-google bui-button bui-button--secondary nw-social-btn-google"
               data-ga-action="click" data-ga-label="Sign in with google" style="width: 50%;">
                <div class="access-panel__social-button-content">
                    <div class="access-panel__social-button-image">
                        <svg viewBox="0 0 262 262" xmlns="http://www.w3.org/2000/svg"
                             preserveAspectRatio="xMidYMid" aria-labelledby="google-label" width="24"
                             height="24" role="img">
                            <path
                                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                                    fill="#4285F4"></path>
                            <path
                                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                                    fill="#34A853"></path>
                            <path
                                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                                    fill="#FBBC05"></path>
                            <path
                                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                                    fill="#EB4335"></path>
                        </svg>
                    </div>
                    <div class="access-panel__social-button-text">
                        <div
                                class="access-panel__social-button-text-span access-panel__social-button-text-span--long">
                            S'identifier via Google
                        </div>
                        <div id="google-label"
                             class="access-panel__social-button-text-span access-panel__social-button-text-span--short">
                            Google
                        </div>
                    </div>
                </div>
            </button>
        </div>
    </div>
</form>