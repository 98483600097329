import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private _url: string;

    /**
      * constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(private _httpClient: HttpClient,
        private meta: Meta,
        private title: Title) {
        this._url = environment.url_api;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([

            ]).then(
                ([]) => {

                    resolve([]);
                },
                reject
            );
        });
    }
    generateTags(config): void {
        // default values
        config = {
            title: 'TicTacSanté',
            description: 'Description de la page par défaut',
            image: 'http://imagepardefaut.com',
            keywords: 'TicTacSanté, Médecin, Praticien',
            ...config
        };
        this.meta.updateTag({ name: 'description', content: config.description });
        this.meta.updateTag({ name: 'keywords', content: config.keywords });

        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({ name: 'twitter:site', content: '@twitter' });
        this.meta.updateTag({ name: 'twitter:title', content: config.title });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:image', content: config.image });

        this.meta.updateTag({ property: 'og:type', content: 'article' });
        this.meta.updateTag({ property: 'og:site_name', content: 'SiteName' });
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: `https://monurl/` });

        this.title.setTitle(config.title);
        this.meta.addTags([
            { name: 'description', content: config.description },
            { name: 'keywords', content: config.keywords },
        ]);
    }

}