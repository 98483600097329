<div class="logo">
	<svg version="1.1" id="Calque_1" height="70" xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 348 92"
		style="enable-background:new 0 0 348 92;" xml:space="preserve">
		<style type="text/css">
			.st0 {
				fill: #6FCBDB;
			}

			.st1 {
				fill: #0FA0D7;
			}

			.st2 {
				fill: #C4BCBC;
			}

			.st3 {
				fill: #17B3A5;
			}

			.st4 {
				fill: #999999;
			}
		</style>
		<g>
			<g>
				<g>
					<path class="st0" d="M118,62.4c-0.7,0.4-1.4,0.7-2.1,0.9c-0.7,0.2-1.3,0.3-1.9,0.3c-0.8,0-1.3-0.2-1.7-0.7s-0.5-1.3-0.5-2.5
				c0-0.8,0.1-3.2,0.2-7.2s0.2-7.6,0.2-10.9c0-0.8,0-2.6-0.1-5.2c0-1.3,0-2.3,0-3h-1.8c-2.3,0-4.1,0.1-5.5,0.3
				c-1.4,0.2-2.5,0.5-3.2,1c-0.4-0.7-0.6-1.3-0.8-1.8c-0.2-0.5-0.3-0.9-0.3-1.3c0-0.7,0.2-1.2,0.7-1.5c0.5-0.3,1.3-0.4,2.5-0.4
				c0.6,0,2.4,0.1,5.3,0.2c2.9,0.1,5.6,0.2,7.9,0.2c2.8,0,5-0.1,6.7-0.3c1.7-0.2,2.9-0.6,3.8-1c0.3,0.5,0.5,0.9,0.7,1.4
				c0.1,0.4,0.2,0.8,0.2,1.1c0,0.8-0.3,1.5-0.9,1.8c-0.6,0.4-1.6,0.6-2.9,0.6c-0.5,0-1.6,0-3.5-0.1c-1.8-0.1-3.3-0.1-4.4-0.2
				c-0.1,2.7-0.2,5.3-0.2,7.7s-0.1,4.6-0.1,6.6c0,3.9,0.1,6.9,0.4,9.1C117.1,59.7,117.5,61.4,118,62.4z" />
					<path class="st0" d="M136.1,62.5c-0.7,0.4-1.3,0.6-1.9,0.8s-1.2,0.3-1.7,0.3c-0.8,0-1.3-0.2-1.5-0.6c-0.3-0.4-0.4-1.2-0.4-2.5
				c0-0.7,0.1-2.3,0.2-4.8c0.1-2.5,0.2-4.6,0.2-6.4c0-1.9-0.1-3.5-0.3-4.7c-0.2-1.2-0.5-2.2-0.9-3.1c0.6-0.3,1.2-0.6,1.8-0.8
				s1.2-0.3,1.8-0.3c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.4,1,0.4,1.9c0,0.2-0.1,1.6-0.2,4.1c-0.1,2.5-0.2,4.8-0.2,7
				c0,2.1,0.1,3.8,0.3,5.2C135.3,60.5,135.7,61.7,136.1,62.5z M135.9,33.4c0,0.9-0.3,1.6-0.9,2.2s-1.4,0.9-2.3,0.9
				c-0.8,0-1.5-0.2-2-0.7c-0.5-0.5-0.7-1.1-0.7-1.9c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.4-0.9,2.3-0.9c0.8,0,1.5,0.2,2,0.7
				C135.6,32,135.9,32.6,135.9,33.4z" />
					<path class="st0" d="M156.7,44.8c-0.6-0.4-1.2-0.6-1.8-0.8s-1.2-0.2-1.9-0.2c-2.4,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.3,3.8-2.3,6.4
				c0,2.3,0.7,4.2,2.1,5.6c1.4,1.4,3.3,2.1,5.6,2.1c1,0,2-0.1,2.8-0.4c0.8-0.3,1.6-0.7,2.3-1.3c0.3,0.4,0.5,0.8,0.6,1.1
				c0.1,0.4,0.2,0.7,0.2,1c0,1-0.6,1.8-1.7,2.3c-1.1,0.6-2.7,0.9-4.6,0.9c-3.4,0-6.1-1-8.3-2.9c-2.1-1.9-3.2-4.5-3.2-7.6
				c0-3.8,1.3-7,3.9-9.5c2.6-2.6,5.7-3.9,9.4-3.9c1.3,0,2.4,0.2,3,0.6c0.7,0.4,1,1,1,1.8c0,0.4-0.1,0.8-0.3,1.2
				C157.5,44,157.2,44.4,156.7,44.8z" />
					<path class="st1" d="M174.1,62.4c-0.7,0.4-1.4,0.7-2.1,0.9c-0.7,0.2-1.3,0.3-1.9,0.3c-0.8,0-1.3-0.2-1.7-0.7s-0.5-1.3-0.5-2.5
				c0-0.8,0.1-3.2,0.2-7.2s0.2-7.6,0.2-10.9c0-0.8,0-2.6-0.1-5.2c0-1.3,0-2.3,0-3h-1.8c-2.3,0-4.1,0.1-5.5,0.3
				c-1.4,0.2-2.5,0.5-3.2,1c-0.4-0.7-0.6-1.3-0.8-1.8c-0.2-0.5-0.3-0.9-0.3-1.3c0-0.7,0.2-1.2,0.7-1.5c0.5-0.3,1.3-0.4,2.5-0.4
				c0.6,0,2.4,0.1,5.3,0.2c2.9,0.1,5.6,0.2,7.9,0.2c2.8,0,5-0.1,6.7-0.3c1.7-0.2,2.9-0.6,3.8-1c0.3,0.5,0.5,0.9,0.7,1.4
				c0.1,0.4,0.2,0.8,0.2,1.1c0,0.8-0.3,1.5-0.9,1.8c-0.6,0.4-1.6,0.6-2.9,0.6c-0.5,0-1.6,0-3.5-0.1c-1.8-0.1-3.3-0.1-4.4-0.2
				c-0.1,2.7-0.2,5.3-0.2,7.7s-0.1,4.6-0.1,6.6c0,3.9,0.1,6.9,0.4,9.1C173.2,59.7,173.6,61.4,174.1,62.4z" />
					<path class="st1" d="M196.5,62.4c-0.6,0.4-1.2,0.7-1.6,0.9c-0.5,0.2-0.9,0.3-1.3,0.3c-0.7,0-1.1-0.2-1.5-0.6s-0.5-1.1-0.5-2
				c-1.2,1-2.3,1.7-3.5,2.2s-2.3,0.7-3.5,0.7c-2.3,0-4.2-0.7-5.6-2c-1.4-1.3-2.1-3-2.1-5.1c0-2.4,1-4.3,2.9-5.8
				c2-1.4,4.6-2.2,7.9-2.2c0.5,0,1.1,0,1.7,0.1s1.3,0.1,2,0.2c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.3,0-0.5c0-1.6-0.5-2.8-1.4-3.7
				c-0.9-0.8-2.2-1.3-3.9-1.3c-1.4,0-2.6,0.2-3.5,0.7c-0.9,0.5-1.7,1.2-2.2,2.2c-0.6-0.1-1-0.4-1.3-0.7c-0.3-0.3-0.4-0.8-0.4-1.3
				c0-1.1,0.7-2.1,2.1-2.8c1.4-0.7,3.3-1.1,5.6-1.1c2.8,0,5,0.7,6.4,2.1s2.1,3.4,2.1,6.1c0,0.5,0,1.4-0.1,2.7c0,1.3-0.1,2.3-0.1,3.1
				c0,2.1,0.1,3.9,0.4,5.2C195.5,60.5,195.9,61.6,196.5,62.4z M191.4,52c-0.7-0.1-1.4-0.2-1.9-0.2s-1-0.1-1.5-0.1
				c-2.4,0-4.2,0.4-5.4,1.2c-1.2,0.8-1.8,2-1.8,3.6c0,1.3,0.4,2.3,1.3,3c0.9,0.7,2,1.1,3.5,1.1c1.1,0,2.2-0.2,3.2-0.6
				c1-0.4,1.9-1.1,2.7-1.9c0-0.8-0.1-1.4-0.1-1.9c0-0.5,0-1,0-1.4c0-0.3,0-0.7,0-1.3C191.4,52.8,191.4,52.3,191.4,52z" />
					<path class="st1" d="M216.4,44.8c-0.6-0.4-1.2-0.6-1.8-0.8s-1.2-0.2-1.9-0.2c-2.4,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.3,3.8-2.3,6.4
				c0,2.3,0.7,4.2,2.1,5.6c1.4,1.4,3.3,2.1,5.6,2.1c1,0,2-0.1,2.8-0.4c0.8-0.3,1.6-0.7,2.3-1.3c0.3,0.4,0.5,0.8,0.6,1.1
				c0.1,0.4,0.2,0.7,0.2,1c0,1-0.6,1.8-1.7,2.3c-1.1,0.6-2.7,0.9-4.6,0.9c-3.4,0-6.1-1-8.3-2.9c-2.1-1.9-3.2-4.5-3.2-7.6
				c0-3.8,1.3-7,3.9-9.5c2.6-2.6,5.7-3.9,9.4-3.9c1.3,0,2.4,0.2,3,0.6c0.7,0.4,1,1,1,1.8c0,0.4-0.1,0.8-0.3,1.2
				C217.1,44,216.8,44.4,216.4,44.8z" />
					<path class="st2" d="M219.1,55.3c0.2-1.5,0.5-2.4,1-2.9c0.2-0.2,0.4-0.2,0.6,0.2c0.7,1.2,1.6,2.1,2.8,2.8c1.2,0.7,2.6,1.1,4,1.2
				c0.3,0,0.7,0,1,0.1c1.1,0,2.2-0.1,3.3-0.4c1.5-0.3,2.8-0.8,4-1.4c1.2-0.6,2.2-1.5,3-2.4c0.8-1,1.2-2,1.2-3.1
				c0-0.8-0.6-1.7-1.7-2.7c-1.2-1-2.4-1.9-3.8-2.6c-1.3-0.7-2.7-1.5-4.2-2.2c-1.5-0.7-2.3-1.2-2.5-1.3c-1.4-0.9-2.5-2-3.1-3.3
				c-0.6-1.2-1-2.5-1-3.8c0-0.1,0-0.2,0-0.2c0.1-4.1,2.9-7.9,8.4-11.5c2-1.3,4.2-2.3,6.6-2.8c1.7-0.4,3.2-0.6,4.7-0.6
				c0.7,0,1.3,0,1.9,0.1c2,0.2,3.4,0.8,4.4,1.7c0.9,0.8,1.3,1.9,1.3,3.4c0,0.8-0.1,1.6-0.3,2.5c-0.1,0.4-0.2,0.6-0.3,0.8
				c-0.1,0.2-0.3,0.3-0.5,0.3s-0.3,0-0.5-0.1c-0.1,0-0.3-0.1-0.5-0.2c-0.5-0.3-1.3-0.6-2.3-0.9c-1-0.3-2.2-0.4-3.6-0.4
				c-0.1,0-0.3,0-0.4,0c-1.2,0-2.5,0.1-3.7,0.4c-1.4,0.3-2.8,0.8-4.1,1.7s-2.5,1.9-3.4,3.3c-0.4,0.7-0.6,1.3-0.6,1.8
				c0,0.5,0.1,1,0.4,1.5c0.3,0.5,0.7,0.9,1.1,1.3c0.5,0.4,1.1,0.8,1.8,1.2c0.7,0.4,1.5,0.8,2.2,1.2s1.6,0.9,2.6,1.4s1.9,1.2,2.7,1.8
				c2,1.4,3.4,3,4.3,4.6c0.7,1.3,1.1,2.6,1.1,3.9c0,0.3,0,0.6-0.1,0.9c-0.2,1.5-0.8,3.1-1.7,4.5c-0.9,1.5-2.2,2.8-3.7,4
				c-1.5,1.2-3.2,2.2-5.1,3.1c-2.8,1.1-5.4,1.7-7.9,1.7c-0.6,0-1.1,0-1.7-0.1c-2.9-0.3-5.1-1.3-6.6-3c-0.8-0.9-1.2-2.2-1.2-3.8
				C219,56.3,219,55.8,219.1,55.3z" />
					<path class="st2" d="M250,54.5c0.1-1.3,0.3-2.6,0.6-3.8s0.9-2.7,1.8-4.2c0.9-1.5,2-2.9,3.4-4.2c1.4-1.3,2.8-2.3,4-2.9
				c1.2-0.6,2.1-0.9,2.9-0.9c0.1,0,0.1,0,0.2,0c0.8,0,1.5,0.3,2.2,0.7c0.7,0.4,1.1,0.9,1.5,1.5c0.3,0.5,0.6,1.1,0.8,1.8v0.1
				c0.3-1.4,0.7-2.6,1-3.7c0.1-0.3,0.4-0.4,1-0.4c0.1,0,0.1,0,0.2,0c0.5,0,1.1,0.1,1.8,0.2c1,0.3,1.9,0.8,2.7,1.5c0.6,0.6,1,1.3,1,2
				c0,0.1,0,0.3,0,0.4c0,0.6-0.2,1.6-0.7,2.8c-0.4,1.3-1.1,2.8-1.8,4.6s-1.2,2.9-1.3,3.2c-0.4,1.3-0.6,2.3-0.6,3.1
				c0,0.6,0.1,1,0.2,1.4c0.1,0.3,0.4,0.4,0.6,0.4c0.6,0,1.6-0.7,2.9-2.1c0.4-0.4,1.1-1.4,2.1-2.9c1-1.5,1.8-2.9,2.4-4
				c0.2-0.4,0.7-0.5,1.4-0.1c0.7,0.4,0.9,0.7,0.8,1.1c-0.6,1.4-1.6,3.2-3.1,5.5c-1.5,2.2-2.9,4-4.2,5.3c-0.9,0.9-1.9,1.5-3.1,2
				c-0.9,0.4-1.8,0.6-2.6,0.6c-0.2,0-0.4,0-0.5,0c-2.4-0.2-3.6-2-3.6-5.4c0-0.1,0-0.2,0-0.4c-1.2,1.7-2.3,2.9-3.5,3.8
				c-1.2,1-2.4,1.6-3.5,1.8c-0.4,0.1-0.8,0.1-1.2,0.1c-0.6,0-1.2-0.1-1.7-0.4c-0.8-0.4-1.5-0.9-2.1-1.6c-0.6-0.7-1-1.4-1.2-2.2
				c-0.2-0.5-0.3-1.2-0.4-1.9c-0.1-0.4-0.1-1-0.1-1.6C249.9,55.6,250,55.1,250,54.5z M257.3,56.7c0,1,0.2,1.7,0.6,2.1
				c0.2,0.2,0.5,0.3,0.7,0.3c0.6,0,1.3-0.5,2.1-1.4c1.2-1.4,2.2-3,3-4.9c0.9-2.2,1.3-4,1.3-5.4c0-0.7-0.1-1.4-0.4-1.9
				c-0.3-0.7-0.8-1.1-1.4-1.1c-0.2,0-0.3,0-0.6,0.1c-0.4,0.1-0.9,0.4-1.3,0.9c-1.1,1.3-2.1,3-3,5.3c-0.3,0.8-0.6,1.8-0.8,2.9
				c-0.2,1-0.3,2-0.3,2.9C257.3,56.5,257.3,56.6,257.3,56.7z" />
					<path class="st2" d="M275.3,60c0-0.6,0.2-1.6,0.5-3c0.4-1.4,1-3.7,1.8-6.9c0.9-3.3,1.6-7.1,2.4-11.4c0.1-0.1,0.4-0.2,0.8-0.2
				c0.3,0,0.7,0,1.2,0.1c1.1,0.2,2.2,0.8,3.4,1.7c0.9,0.8,1.3,1.6,1.3,2.4c0,0.2,0,0.4-0.1,0.6c-0.2,1-0.5,1.9-0.7,2.7
				c0.6-0.9,1.2-1.7,1.8-2.4c0.6-0.7,1.4-1.4,2.2-2.1c0.8-0.7,1.7-1.2,2.6-1.5c0.9-0.4,1.8-0.6,2.9-0.7c0.2,0,0.4,0,0.5,0
				c0.8,0,1.7,0.2,2.5,0.5c0.7,0.2,1.2,0.6,1.6,1.2c0.4,0.5,0.6,1.1,0.7,1.7c0.1,0.6,0.1,1.2,0,1.7c-0.1,0.5-0.3,1-0.5,1.3
				c0,0-0.2,0.4-0.7,1.3c-0.4,0.8-0.9,1.9-1.3,3.2c-0.4,1.3-0.8,2.9-1.1,4.6c-0.1,0.8-0.2,1.4-0.2,1.9c0,0.8,0.1,1.2,0.3,1.4
				c0.1,0,0.1,0.1,0.2,0.1c0.4,0,1.1-0.6,2.1-1.7c0.4-0.5,1.1-1.5,2.2-3c1-1.5,1.8-2.7,2.4-3.7c0.2-0.3,0.5-0.4,0.8-0.3
				s0.7,0.2,0.9,0.5s0.4,0.6,0.3,0.8c-0.6,1.4-1.6,3.2-3,5.4c-1.4,2.2-2.8,4-4.2,5.2c-1.3,1.2-2.8,1.9-4.5,2.1c-0.3,0-0.5,0-0.8,0
				c-1.4,0-2.5-0.4-3.3-1.1c-0.6-0.5-1-1.4-1.1-2.7c0-0.4-0.1-0.8-0.1-1.2c0-0.9,0.1-1.7,0.3-2.7c0.2-1.4,0.6-2.8,1.1-4.2l1.6-4.1
				c0.1-0.3,0.1-0.6,0.1-0.8c0-0.6-0.3-0.9-0.9-1c-0.1,0-0.2,0-0.3,0c-0.9,0-1.9,0.5-3.2,1.6c-2.2,1.8-3.7,4.4-4.7,7.7
				c-0.2,0.8-0.4,1.5-0.5,2.4c0,0,0,0,0,0.1c0,0,0,0,0,0.1c-0.2,1.5-0.5,3.3-0.7,5.4c-0.3,0.3-0.9,0.6-1.9,0.6c-0.1,0-0.2,0-0.4,0
				c-0.9,0-1.9-0.3-2.9-0.9C275.9,62,275.3,61.1,275.3,60z" />
					<path class="st2" d="M302.2,60c-0.1-0.5-0.1-1.1-0.1-1.7c0-0.6,0-1.3,0.1-1.9c0.2-1.3,0.4-2.5,0.7-3.5c0.3-1,0.6-2,0.9-3
				c1.4-4.8,2.5-8.6,3.3-11.4l-1.3-0.1c-0.5,0-0.9,0-1.2-0.1s-0.5-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-1.1
				c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3-0.1,0.5-0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.7,0,1.2,0c0.5,0,1.1,0,1.6,0
				c1-4.1,1.7-7.4,2.1-9.8c0-0.1,0.1-0.2,0.3-0.3c0.2-0.1,0.5-0.1,0.8,0c0.3,0.1,0.7,0.1,1.1,0.2c0.4,0.1,0.8,0.3,1.2,0.5
				c0.4,0.2,0.8,0.4,1.2,0.7c1.3,0.9,2,1.8,2,2.8c0,0.2,0,0.4-0.1,0.6c-0.1,0.6-0.6,2.1-1.4,4.5c0.2,0,0.6-0.1,1.1-0.2
				s0.7-0.2,0.8-0.2c0.7-0.2,1.1,0.2,1.1,1c0,0.9-0.3,1.7-0.9,2.5c-0.4,0.5-0.7,0.7-1,0.6c-0.2,0-0.6,0-1.1,0c-0.6,0-1,0-1.2,0
				c-2.3,7.2-3.9,12.6-4.7,16.3c-0.2,0.9-0.3,1.7-0.2,2.3c0,0.9,0.2,1.4,0.5,1.6c0.1,0.1,0.2,0.1,0.4,0.1c0.6,0,1.6-0.7,2.8-2.2
				c0.4-0.5,1.1-1.5,2.1-3c1-1.5,1.8-2.8,2.3-3.8c0.2-0.2,0.5-0.3,0.8-0.2c0.3,0.1,0.7,0.3,1,0.6c0.3,0.3,0.4,0.5,0.3,0.7
				c-0.7,1.5-1.7,3.3-3.1,5.5c-1.4,2.2-2.8,3.9-4.1,5.2c-0.7,0.7-1.5,1.2-2.6,1.6c-0.9,0.4-1.8,0.5-2.9,0.5c-0.1,0-0.2,0-0.4,0
				c-1.1,0-2.1-0.4-2.8-1.2C302.8,61.9,302.4,61.1,302.2,60z" />
					<path class="st2" d="M316,52.9c0.5-3.4,1.9-6.3,4.1-8.7c1.4-1.5,3.1-2.7,5.2-3.6c1.8-0.8,3.4-1.2,4.8-1.2c0.2,0,0.4,0,0.5,0
				c0.8,0,1.7,0.2,2.5,0.6c0.8,0.4,1.6,0.9,2.2,1.6c0.7,0.7,1,1.5,1.1,2.5c0,0.1,0,0.3,0,0.4c0,0.9-0.3,1.8-0.8,2.9
				c-0.6,1-1.4,1.9-2.5,2.8c-1.1,0.9-2.3,1.6-3.6,2.2c-1.3,0.6-2.5,1.1-3.6,1.5c-1.1,0.4-2.2,0.7-3.2,1c0.8,2.6,2.1,3.9,4,3.9
				c0.9,0,1.9-0.3,3.1-0.9c2.2-1.1,4.5-3,6.8-5.7c0.9-1.1,1.5-1.9,1.8-2.4c0.2-0.2,0.4-0.3,0.7-0.2s0.6,0.2,0.9,0.5
				c0.3,0.3,0.5,0.6,0.5,0.8c-0.6,1.5-1.6,3-2.8,4.6c-1.2,1.6-2.7,3.1-4.3,4.4c-1.7,1.3-3.4,2.4-5.2,3.1c-1.3,0.5-2.6,0.8-3.9,0.8
				c-0.5,0-1.1-0.1-1.6-0.2c-1.9-0.3-3.6-1.2-5.1-2.8c-1.3-1.4-1.9-3.3-1.9-5.6C315.8,54.4,315.9,53.6,316,52.9z M323,51.5
				c0.6-0.2,1.6-0.5,2.9-1.1c1.3-0.6,2.4-1.4,3.5-2.3c0.6-0.5,0.9-1.1,0.9-1.8c0-0.1,0-0.2,0-0.3c-0.1-0.8-0.5-1.3-1.2-1.4
				c-0.1,0-0.2,0-0.3,0c-1,0-2.3,0.8-3.8,2.5C324.1,48.1,323.4,49.6,323,51.5z" />
				</g>
				<g>
					<path class="st3" d="M321.7,38.6c0.1-0.7,0.2-1.3,0.5-1.9c0.5-1.1,1-2.1,1.9-3c1-1.1,2.1-2.1,3.5-2.7c1.2-0.6,2.4-0.9,3.7-1
				c0.8-0.1,1.7-0.1,2.5,0c0.2,0,0.4,0.1,0.6,0.1c2.4,0.6,2.5,4,0.2,4.7c0,0,0,0,0,0c-1.3,0.4-2.6,0.6-3.9,0.8
				c-1.4,0.2-2.9,0.3-4.3,0.7c-1.7,0.4-3.2,1.1-4.5,2.2C321.8,38.5,321.8,38.5,321.7,38.6z" />
				</g>
			</g>
			<g>
				<g>
					<path class="st2" d="M84.2,45.6C82,19.7,51.9,7.3,35.4,19.9l0.1,0.2c17-8.6,42.4,7.3,42.4,29.4c0,18.1-14.8,30-32,30
				c-11.8,0-33.4-9.5-33.4-33.2C12.4,28.3,25.2,7,50.7,7c21.2,0,32.8,14.8,32.8,14.8l-4.2,3.5L95,26.6L87.1,13l-1.2,5
				c-13.6-5.9-22-13.3-35.3-13.3C19.7,4.8,7.4,29.2,7.4,46c0,13.7,13.5,39,38.7,39c13.4,0,25.5-7.9,32.4-18.3
				c-9,15.1-22.3,19.5-32,19.5c-22.2,0-35.8-18-35.8-18s13.1,19,35.8,19c16.3,0,39.3-12,37.8-42.4L84.2,45.6z" />
					<path class="st0" d="M47.4,53.2c0,0-4.2-3.2-11.5-3.2C28.3,50,24,53.2,24,53.2h0c0,0,4.3,3.3,11.9,3.3
				C43.2,56.5,47.4,53.2,47.4,53.2L47.4,53.2z" />
					<path class="st1" d="M60.4,26.7c0,0-5.2,3.4-9.3,11.6c-4.2,8.6-3.7,14.8-3.7,14.9l0,0c0,0,5.3-3.4,9.5-12
				C61,33,60.4,26.8,60.4,26.7L60.4,26.7z" />
				</g>
			</g>
			<g>
				<g>
					<line class="st4" x1="320.2" y1="68.8" x2="121.1" y2="68.8" />
				</g>
			</g>
			<g>
				<path class="st4" d="M129.1,79.9c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0s-0.2,0-0.3,0l-2.2-7h1l1.7,6l1.7-6h1l-2.1,7
			C129.3,79.8,129.2,79.8,129.1,79.9z" />
				<path class="st4" d="M136,78.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
			c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.8v-1.4c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.2-0.4,0.4-0.6
			c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.1,0.8-0.1s0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v1.4C136.2,78.4,136.1,78.7,136,78.9z M135,75.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.6,0.1-0.9,0.3
			s-0.3,0.5-0.3,0.8v1.4c0,0.7,0.4,1.1,1.1,1.1c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-1.4C135.3,76.3,135.2,76.1,135,75.9
			z" />
				<path class="st4" d="M139.7,79.7c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-1.3,0-1.9-0.6-1.9-1.9v-5.3h0.8V75
			h1.5c0,0.1,0,0.1,0,0.2v0.2v0.2c0,0.1,0,0.1,0,0.2h-1.5V78c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1
			s0.4,0,0.6,0c0,0.1,0,0.1,0,0.2v0.2V79.7z" />
				<path class="st4" d="M142.9,75.4c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.6,0.1s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
			s-0.1,0.4-0.1,0.7v2.8h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C142.9,75.3,142.9,75.3,142.9,75.4z" />
				<path class="st4"
					d="M147.3,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4S146,80,145.8,80c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C147.5,76.8,147.5,77.1,147.3,77.3z M146.4,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C146.7,76.1,146.6,75.9,146.4,75.8z" />
				<path class="st4"
					d="M155.6,78.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.3V82h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.4-0.3,0.6-0.4
			c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.8v1.4
			C155.8,78.4,155.7,78.7,155.6,78.9z M154.6,75.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v1.4
			c0,0.3,0.1,0.6,0.3,0.8s0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8v-1.4C155,76.3,154.9,76.1,154.6,75.9z" />
				<path class="st4" d="M159.2,75.4c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.6,0.1s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
			s-0.1,0.4-0.1,0.7v2.8H157V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C159.2,75.3,159.2,75.3,159.2,75.4z" />
				<path class="st4"
					d="M163.7,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C163.9,76.8,163.8,77.1,163.7,77.3z M162.8,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C163.1,76.1,163,75.9,162.8,75.8z" />
				<path class="st4" d="M171.3,76.8c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.1-0.5-0.1
			c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v3h-0.8v-3.1c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.8-0.3
			c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v3h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.4-0.3,0.7-0.4
			c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.2
			c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.1,0.2,1.4,0.5c0.3,0.4,0.5,0.8,0.5,1.4v3h-0.8V76.8z" />
				<path class="st4" d="M174.3,73.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4
			s0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4S174.4,73.6,174.3,73.7z M173.5,75h0.8v4.9h-0.8V75z" />
				<path class="st4"
					d="M179.4,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4S178,80,177.8,80c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C179.6,76.8,179.5,77.1,179.4,77.3z M178.5,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C178.7,76.1,178.7,75.9,178.5,75.8z" />
				<path class="st4" d="M182.9,75.4c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.6,0.1s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
			s-0.1,0.4-0.1,0.7v2.8h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C182.9,75.3,182.9,75.3,182.9,75.4z" />
				<path class="st4" d="M190.3,79.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3s-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.2-0.8
			c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.4,0,0.7,0.1,1,0.2v-0.2c0-0.4-0.1-0.7-0.3-0.8
			s-0.5-0.3-0.9-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.1-0.1-0.2-0.1-0.3-0.2s-0.2-0.2-0.2-0.3
			c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.8,0.1c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v3.1C190.5,79.8,190.4,79.7,190.3,79.7z M188.9,77.3c-0.2,0-0.3,0-0.5,0s-0.3,0.1-0.4,0.2
			s-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2
			s0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.1-0.3,0.1-0.4v-0.7
			C189.5,77.4,189.2,77.3,188.9,77.3z" />
				<path class="st4" d="M194.3,79.9c-0.1,0-0.1,0-0.2,0h-0.2c-0.6,0-1.1-0.2-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.4v-5.3h0.8V78
			c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.4
			C194.3,79.6,194.3,79.8,194.3,79.9z" />
				<path class="st4" d="M197.5,79.9c-0.1,0-0.1,0-0.2,0h-0.2c-0.6,0-1.1-0.2-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.4v-5.3h0.8V78
			c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.4
			C197.5,79.6,197.5,79.8,197.5,79.9z" />
				<path class="st4" d="M199.2,73.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4
			s0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4S199.3,73.6,199.2,73.7z M198.4,75h0.8v4.9h-0.8V75z" />
				<path class="st4" d="M204.2,77.3c-0.1,0.2-0.3,0.4-0.5,0.5S203.3,78,203,78c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C204.4,76.8,204.3,77.1,204.2,77.3z M203.3,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C203.6,76.1,203.5,75.9,203.3,75.8z
			 M202,74.1l0.7-1.3h0.9l-1,1.3H202z" />
				<path class="st4"
					d="M212.5,78.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.3V82h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.4-0.3,0.6-0.4
			c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.8v1.4
			C212.7,78.4,212.6,78.7,212.5,78.9z M211.5,75.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v1.4
			c0,0.3,0.1,0.6,0.3,0.8s0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8v-1.4C211.8,76.3,211.7,76.1,211.5,75.9z" />
				<path class="st4" d="M217.5,78.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
			c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.8v-1.4c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.2-0.4,0.4-0.6
			c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.1,0.8-0.1s0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v1.4C217.7,78.4,217.7,78.7,217.5,78.9z M216.6,75.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.6,0.1-0.9,0.3
			s-0.3,0.5-0.3,0.8v1.4c0,0.7,0.4,1.1,1.1,1.1c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-1.4
			C216.9,76.3,216.8,76.1,216.6,75.9z" />
				<path class="st4" d="M222.5,79.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.3,0-0.6,0-0.8-0.1s-0.4-0.2-0.6-0.4c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.8V75h0.8v3.1c0,0.3,0.1,0.6,0.3,0.8
			c0.2,0.2,0.4,0.3,0.8,0.3s0.7-0.1,0.9-0.3s0.3-0.5,0.3-0.9V75h0.8v4.9C222.7,79.8,222.6,79.8,222.5,79.7z" />
				<path class="st4" d="M226.4,75.4c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.6,0.1s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
			s-0.1,0.4-0.1,0.7v2.8h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C226.4,75.3,226.4,75.3,226.4,75.4z" />
				<path class="st4" d="M234,79.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1
			s-0.4-0.2-0.6-0.4c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.8V75h0.8v3.1c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.8,0.3
			s0.7-0.1,0.9-0.3s0.3-0.5,0.3-0.9V75h0.8v4.9C234.2,79.8,234.1,79.8,234,79.7z" />
				<path class="st4" d="M238.9,76.8c0-0.4-0.1-0.6-0.3-0.9c-0.2-0.2-0.4-0.3-0.8-0.3c-0.4,0-0.7,0.1-0.9,0.3
			c-0.2,0.2-0.3,0.5-0.3,0.9v3h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.5-0.4,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1
			s0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.3,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.8v3.1h-0.8V76.8z" />
				<path class="st4"
					d="M244.7,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C244.9,76.8,244.8,77.1,244.7,77.3z M243.8,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C244.1,76.1,244,75.9,243.8,75.8z" />
				<path class="st4" d="M255.4,76.8c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.3-0.2-0.4s-0.2-0.2-0.3-0.3s-0.3-0.1-0.5-0.1
			c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v3h-0.8v-3.1c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.8-0.3
			c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v3h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.4-0.3,0.7-0.4
			c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.2
			c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.1,0.2,1.4,0.5c0.3,0.4,0.5,0.8,0.5,1.4v3h-0.8V76.8z" />
				<path class="st4"
					d="M261.2,77.3c-0.1,0.2-0.3,0.4-0.5,0.5S260.3,78,260,78c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C261.4,76.8,261.3,77.1,261.2,77.3z M260.3,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C260.6,76.1,260.5,75.9,260.3,75.8z" />
				<path class="st4" d="M263.4,73.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4
			s0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4S263.5,73.6,263.4,73.7z M262.6,75h0.8v4.9h-0.8V75z" />
				<path class="st4" d="M267.2,79.9c-0.1,0-0.1,0-0.2,0h-0.2c-0.6,0-1.1-0.2-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.4v-5.3h0.8V78
			c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.4
			C267.2,79.6,267.2,79.8,267.2,79.9z" />
				<path class="st4" d="M270.4,79.9c-0.1,0-0.1,0-0.2,0H270c-0.6,0-1.1-0.2-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.4v-5.3h0.8V78
			c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.4
			C270.4,79.6,270.4,79.8,270.4,79.9z" />
				<path class="st4"
					d="M274.8,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C275,76.8,274.9,77.1,274.8,77.3z M273.9,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C274.2,76.1,274.1,75.9,273.9,75.8z" />
				<path class="st4" d="M279.8,79.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.5,0.4-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.3,0-0.6,0-0.8-0.1s-0.4-0.2-0.6-0.4c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.8V75h0.8v3.1c0,0.3,0.1,0.6,0.3,0.8
			c0.2,0.2,0.4,0.3,0.8,0.3s0.7-0.1,0.9-0.3s0.3-0.5,0.3-0.9V75h0.8v4.9C280,79.8,279.9,79.8,279.8,79.7z" />
				<path class="st4" d="M283.7,75.4c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.6,0.1s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
			s-0.1,0.4-0.1,0.7v2.8h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1l0.3,0
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C283.7,75.3,283.7,75.3,283.7,75.4z" />
				<path class="st4"
					d="M288.2,77.3c-0.1,0.2-0.3,0.4-0.5,0.5S287.2,78,287,78c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C288.4,76.8,288.3,77.1,288.2,77.3z M287.3,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C287.6,76.1,287.5,75.9,287.3,75.8z" />
				<path class="st4" d="M295.9,79.2c-0.1,0.2-0.2,0.3-0.4,0.5s-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1
			s-0.4-0.1-0.6-0.2s-0.3-0.2-0.5-0.4c-0.1-0.2-0.2-0.3-0.3-0.6c0.1-0.1,0.2-0.1,0.3-0.2s0.2,0,0.4,0c0,0,0,0,0.1,0s0,0,0.1,0
			c0,0.1,0.1,0.2,0.2,0.3s0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4,0c0.1,0,0.2,0,0.4,0s0.2-0.1,0.3-0.1
			c0.1-0.1,0.2-0.1,0.2-0.2s0.1-0.2,0.1-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.7-0.2
			c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.4-0.2-0.5-0.4s-0.2-0.4-0.2-0.7c0-0.3,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4
			s0.3-0.2,0.6-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.7,0.1,1.1,0.2c0.3,0.1,0.6,0.4,0.8,0.7c-0.1,0.1-0.2,0.2-0.4,0.2
			c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.7,0.2
			c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7C296,78.7,296,79,295.9,79.2z" />
				<path class="st4" d="M300.6,79.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3s-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.2-0.8
			c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.4,0,0.7,0.1,1,0.2v-0.2c0-0.4-0.1-0.7-0.3-0.8
			c-0.2-0.2-0.5-0.3-0.9-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.1-0.1-0.2-0.1-0.3-0.2s-0.2-0.2-0.2-0.3
			c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.8,0.1c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v3.1C300.8,79.8,300.7,79.7,300.6,79.7z M299.2,77.3c-0.2,0-0.3,0-0.5,0s-0.3,0.1-0.4,0.2
			s-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.4,0.2
			s0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.1-0.3,0.1-0.4v-0.7
			C299.8,77.4,299.5,77.3,299.2,77.3z" />
				<path class="st4" d="M305.4,76.8c0-0.4-0.1-0.6-0.3-0.9c-0.2-0.2-0.4-0.3-0.8-0.3c-0.4,0-0.7,0.1-0.9,0.3
			c-0.2,0.2-0.3,0.5-0.3,0.9v3h-0.8V75c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.2c0.2-0.2,0.5-0.4,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1
			s0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.3,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.8v3.1h-0.8V76.8z" />
				<path class="st4" d="M309.9,79.7c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-1.3,0-1.9-0.6-1.9-1.9v-5.3h0.8V75
			h1.5c0,0.1,0,0.1,0,0.2v0.2v0.2c0,0.1,0,0.1,0,0.2h-1.5V78c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1
			s0.4,0,0.6,0c0,0.1,0,0.1,0,0.2v0.2V79.7z" />
				<path class="st4" d="M314.5,77.3c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.2
			c0,0,0,0,0,0.1s0,0.1,0,0.1v0.1c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.3,0.9,0.3c0.4,0,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.2-1.6-0.5s-0.6-0.8-0.6-1.5v-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.7C314.7,76.8,314.7,77.1,314.5,77.3z M313.6,75.8
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.4,0.9v0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4C313.9,76.1,313.8,75.9,313.6,75.8z
			 M312.3,74.1l0.7-1.3h0.9l-1,1.3H312.3z" />
			</g>
		</g>
	</svg>
</div>