import {inject, Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {map} from "rxjs/operators";
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";
import {HelperStateManagerService} from "../../../../@ea/helpers/helper.state-manager.service";
import {STATUS_OK} from "../../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class ProchesService {

  private api: string = environment.url_api;
  proches = [];
  mesProches : any[];
  prochePrincipal;
  prochePrincipalSubject = new BehaviorSubject<any>(null);
  mesProchesSubject = new BehaviorSubject<any>(null);
  helperStateManagerService = inject(HelperStateManagerService);

  constructor(private _eaHttpAbstract: EaHttpAbstract) {}


  getMesProches(type: string): Observable<any>{
    return this._eaHttpAbstract.post("/patient_area/relative", {type: type})
        .pipe(
            map((response: any)=>{
                if(response.status == STATUS_OK){
                    this.mesProches = response.data;
                    this.emitMesProches();
                    this.prochePrincipal = this.mesProches.find(proche=> proche.is_principal === true );
                    this.emitProchePrincipal();
                }
            })
        );
  }

  emitMesProches(){
    this.mesProchesSubject.next(this.mesProches);
  }

  emitProchePrincipal(){
    this.prochePrincipalSubject.next(this.prochePrincipal);
  }

  saveAccount(data) {
      return this._eaHttpAbstract.post("/patient_area/save_account", data)
          .pipe(
              map((response: any)=>{
                  return response;
              })
          );
    }

    updateDataSource(data: any, type) {
        let patients = this.mesProchesSubject.getValue().filter(r => r.reference === data.reference);
        let newState = [];
        if(patients.length !== 0) {
            if(type === "delete"){
                newState = this.helperStateManagerService.delete(data, this.mesProchesSubject.value, 'reference');
            }else {
                newState = this.helperStateManagerService.update(data, this.mesProchesSubject.value, 'reference');
            }
        }else {
            newState = this.helperStateManagerService.create(data, this.mesProchesSubject.value);
        }
        this.mesProchesSubject.next(newState);
    }

    deleteProche(references) {
        return this._eaHttpAbstract.delete("/patient_area/delete_account", {references})
            .pipe(
                map((response: any)=>{
                    return response;
                })
            );
    }
}
