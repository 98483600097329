import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '../booking.service';
import {DEFAULT_PHOTO} from "../../../constants/constants";
import {ImageUtils} from "../../../../@ea/helpers/image-utils";
import {SharedModule} from "../../../shared/shared.module";
import {PlanningComponent} from "../../search-area/result/list/planning/planning.component";
import {PlanningService} from "../../../apps/planning/planning.service";

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  standalone:true,
  imports: [SharedModule, PlanningComponent],
  providers: [PlanningService]
})
export class VerificationComponent implements OnInit {

  appointement: any;
  practice: any;
  timeSlot: any;
  time: any;
  motif: any;
  dataPractice: any;
  isAvailableSlot: boolean = true;
  spin: boolean = true;
  public isDisabledNextButton: boolean = true;
  public errorTimesSlots: boolean = false;
  public errorNewPatient: boolean = false;
  constructor(
      public router: Router,
      private _bookingService : BookingService
  ) { }
  ngOnInit(): void {
    this.checkAvailability();
  }
  checkAvailability(){
    this._bookingService.appointmentSelected.subscribe(val=>{
      if(val){
        this.spin = true;
        this.appointement = val;
        this.practice = this.appointement?.practice;
        this.timeSlot = this.appointement?.timeSlot;
        this.time = this.appointement?.time;
        this.motif = this.appointement?.motif;
        this.dataPractice = {
          title:   this.practice?.title,
          speciality: this.practice?.speciality,
          city: this.practice?.city,
          picture: this.practice?.picture,
          reference_practice: this.practice?.reference_practice?this.practice?.reference_practice:this.practice?.reference,
        }
        const data = {
          start_hour: this.time?.min_hour,
          type: this.motif?.reference,
          date: this.timeSlot?.date
        }
        this._bookingService.checkAvailabilitySlot(data).subscribe((res: any)=>{
          if(res?.data?.error_new_patient === true){
            this.errorNewPatient = true;
            this.isAvailableSlot = false;
          }else if(res?.data?.error_timeslots){
            this.errorTimesSlots = true;
            this.isAvailableSlot = false;
          }else {
            this.isAvailableSlot = res?.data?.is_available;
          }
          this.isDisabledNextButton = !this.isAvailableSlot;
          this.spin = false;
        })
      }
    })
  }
  defaultAvatarTTSUrl = DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
  imageUtils = new ImageUtils();

  selectSlot($event: any) {
    this._bookingService.appointmentSelected.next($event);
  }
}