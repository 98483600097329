import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { locale } from 'moment';
import { ListService } from 'src/app/main/search-area/result/list/list.service';
import { AppService } from "src/app/app.service";
import {emailValidator} from '../../utils/app-validators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public currentDate = new Date();
    public lat: number = 40.678178;
    public lng: number = -73.944158;
    public zoom: number = 12;
    public mapStyles: any = [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#8b9198"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#323336"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#414954"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#2e2f31"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7a7c80"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#242427"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#393a3f"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#393a3f"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#202124"
                }
            ]
        }
    ];
    public feedbackForm: FormGroup;
    public _router: Router;
    public subscribeForm: FormGroup;
    public url_origin: string = window.location.origin;
    private _listService: ListService;
    private _appService: AppService;
    constructor(public formBuilder: FormBuilder, _listService : ListService, _appService: AppService, _router: Router) {
        this._listService = _listService;
        this._appService = _appService;
        this._router = _router;
    }

    default = {
        city: "",
        country: "",
        keyword: ""
    };

    city = "";
    country = "";
    specialities : [string];
    sp1 : any;
    sp2 : any;

    
    frequents = [
        {
          url:'fr/paris/medecin_generaliste',
          location:'Paris, France',
          city: 'paris',
          country:'fr',
          speciality: 'Médecin généraliste'
        },{
          url:'ma/rabat/medecin_generaliste',
          location:'Rabat, Maroc',
          city: 'rabat',
          country:'ma',
          speciality: 'Médecin généraliste'
        },{
          url:'fr/paris/sage_femme',
          location:'Paris, France',
          city: 'paris',
          country:'fr',
          speciality: 'Sage-femme'
        },{
          url:'fr/paris/chirurgien_dentaire',
          location:'Paris, France',
          city: 'paris',
          country:'fr',
          speciality: 'Chirurgien Dentaire'
        },{
          url:'ma/rabat/chirurgien_dentaire',
          location:'Rabat, Maroc',
          city: 'rabat',
          country:'ma',
          speciality: 'Chirurgien Dentaire'
        }
      ];
      
      setFrequentSearch(param: any){
        if(this.frequents[this.frequents.length - 1].url !== `${param.country}/${param.city}/${param.speciality}`){
            this.frequents?.shift();
            // console.log(param);
            let location = param?.country?.toLowerCase() === 'ma'? 'Maroc' : 'France';
            this.frequents.push({
                city: param.city,
                country: param.country,
                location: `${this._appService.titleCaseWord(param.city)}, ${location}`,
                speciality: param.speciality_name,
                url:`${param.country}/${param.city}/${param.speciality}`
            });
        }
      }

    ngOnInit() {
        if(localStorage.getItem("searchHistory")){
            let params = JSON.parse(localStorage.getItem("searchHistory"));
            if(params.country && params.city && params.speciality)
                this.setFrequentSearch(params);
        }
        let Data = this.getLocalStorageData();
        this.city = Data.city;
        this.country = Data.country;
        this._listService.search.subscribe((data) => {
            if(data){
                this.specialities = data.listSpecialites;
                this.sp1 = this.specialities?.slice(0, 20);
                this.sp2 = this.specialities?.slice(21, 40);
            }
        });
        // if chosen and current
        //change location 3la 7seb location
        //else
        //change 3la 7sab fr paris or ma rabat as default

        this.feedbackForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])],
            message: ['', Validators.required]
        });
        this.subscribeForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])]
        })
    }
    redirect(url: any){
        this._router.navigate([`${url}`]).then(()=>location.reload());
    }

    getLocalStorageData(){
        let net_infos = null;
        if(localStorage.getItem("chosenLocation")){
            net_infos = JSON.parse(localStorage.getItem("chosenLocation"));
        } 
        if(!net_infos?.city) {
            if(localStorage.getItem("currentLocation"))
                net_infos = JSON.parse(localStorage.getItem("currentLocation"));
        }
        const Data: any = { };
        Data.keyword = "";
        Data.city = net_infos?.city?.toLowerCase();
        Data.country = net_infos?.country?.toLowerCase();
        if(Data.city == null || !Data.city.length){
            if(localStorage.getItem("network_data")){
                net_infos = JSON.parse(localStorage.getItem("network_data"));
            }
            this.default.city = net_infos.city.toLowerCase();
            this.default.country = net_infos.country.toLowerCase();
            if(this.default.country === 'ma'){
                Data.country = 'ma';
                Data.city = 'rabat';
            } else {
                Data.country = 'fr';
                Data.city = 'paris';
            }
        }
        return Data;
    }

    redirectToDoctor(url: string){
        let Data = this.getLocalStorageData();
        if(Data){
            this.city = Data.city;
            this.country = Data.country;
        }
        this._router.navigate([`${this.country}/${this.city}/${url}`]).then(()=>location.reload());
    }
    public onFeedbackFormSubmit(values: Object): void {
        if (this.feedbackForm.valid) {
            console.log(values);
        }
    }

    public onSubscribeFormSubmit(values: Object): void {
        if (this.subscribeForm.valid) {
            console.log(values);
        }
    }

}
