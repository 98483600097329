import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  standalone: true,
  imports:[
      MatCardModule,
      MatIconModule,
  ]
})
export class MaintenancePageComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');    
  }

}
