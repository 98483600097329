import {defaultPhoto} from "../../app/constants/constants";

export class ImageUtils{
    isImageUrl(str) {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(jpeg|jpg|gif|png|webp)$/i;
        return urlRegex.test(str);
    }

    getPhoto(photoUrl: string) {
        const isValidUrl = this.isImageUrl(photoUrl);
        if(isValidUrl){
            return photoUrl;
        }else return defaultPhoto;
    }
}