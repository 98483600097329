import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/helpers/utils.service';
import { SecurityService } from 'src/app/main/security/security.service';
import { Subject } from "rxjs";
import {STATUS_OK} from "../../../app.constants";
import {ProfileService} from "../../../main/patient-area/profile/profile.service";
import {PATH_AVATAR} from "../../../constants/constants";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  isConnected : boolean = false;
  user :any;
  destroy = new Subject();
  constructor(public appService:AppService,
    private _securityService: SecurityService,
    private _utilsService : UtilsService,
    private _profileService: ProfileService,
    private router: Router) {
    this._securityService.isConnectedSubject.subscribe(
      (connected) => {
        this.isConnected = connected;
      });
     this._profileService.accountInfo.subscribe(
      (user) => {
          if(user){
              this.user = user;
          }
      });
      if(!this.user){
        this.user = this._utilsService.getSession("userdata");
      }
    }

  ngOnInit() {
  }

  ngOnDestroy(){
    this._securityService.isConnectedSubject.unsubscribe();
    this._securityService.userDataSubject.unsubscribe();
    this.destroy.unsubscribe();
  }

  authenticated(){
     this._securityService.isConnectedSubject.subscribe(data=>{
      this.isConnected = data;
      if(this.isConnected){
        this.get_userData();
      }
    }); 
  }

  logout(){
    this._securityService.logout()
    .subscribe((res)=>{
        if (res.status === STATUS_OK) {
            this._utilsService.removeSession('authenticated');
            this._utilsService.removeSession('userdata');
            this._utilsService.removeSession("appointement");
            this._securityService.emitIsConnected(false);
            this.isConnected = false;
            this.router.navigate(['/sign-in']);
      }
    });
  }

  get_userData(){
     this._securityService.userDataSubject.subscribe(userData => {
      this.user = userData
    });
  }

    protected readonly PATH_AVATAR = PATH_AVATAR;
}