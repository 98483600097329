import { Injectable } from '@angular/core';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private analytics: any;

    constructor() {
        this.analytics = Analytics({
            app: 'TicTacSante-app',
            plugins: [
                googleAnalytics({
                    measurementIds: [environment.gtag_id]
                })
            ]
        });
    }

    trackPageView() {
        this.analytics.page();
    }

    trackEvent(eventName: string, eventData: any) {
        this.analytics.track(eventName, eventData);
    }

    identifyUser(userId: string, userInfo: any) {
        this.analytics.identify(userId, userInfo);
    }
}