
<div class="step-content">
  <div class="booking-summary"  style="padding-bottom: 0;">
</div>
<mat-card class="doctor-widget doctor-dashboard" >
  <div class="doc-info-left">
      <div class="doctor-img">
        <a class="doctor-img-profile" href="/">
            <img
                [src]="dataPractice.picture ? 'https://cdn.tictacsante.com/assets/images/doc/' + dataPractice.picture : 'https://cdn.tictacsante.com/assets/images/doc/Default.png'"
                class="img-fluid img-circle" alt="{{dataPractice.title}}"
                (error)="handleImageError($event)"
            ><!--[src]="imageUtils.getPhoto(dataPractice?.photo)" -->
        </a>
      </div>
      <div class="doc-info-cont">
          <h4 class="doc-name m-0"><a href="/">{{dataPractice.title}}</a></h4>
          <div class="toggle-icon-doctor">
              <span class="doc-department">
                  <span class="speciality-img">
                      <img src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg" class="img-fluid"
                          alt="Speciality">
                  </span>
                  <span class="speciality-name">
                      {{dataPractice.speciality}}
                  </span>
              </span>
          </div>
          <div class="rating">
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <mat-icon>star_border</mat-icon>
              <span class="d-inline-block average-rating">(0)</span>
          </div>
          <div class="doc-info custom-info">
              <div class="doc-location">
                  <p>
                      <mat-icon>location_on</mat-icon> {{dataPractice?.city}}
                  </p>
              </div>
          </div>
      </div>
  </div>
</mat-card>

<div class="booking-summary">
  <div class="summary-col">
      <mat-card class="summary-col-inner">
          <mat-spinner [diameter]="48" color="green" class="dialo-progress mr-auto ml-auto mt-10 mb-10" *ngIf="spin"></mat-spinner>
          <div *ngIf="!spin">
              <div *ngIf="isAvailableSlot && timeSlot">
                  <h2 class="text-xl mb-4 mt-0">Confirmez l'heure du rendez-vous</h2>
                  <div>Consultation <span>Au cabinet</span></div>
                  <div>Date <span>{{timeSlot?.date | date}}</span></div>
                  <div>Heure<span>{{time?.hour_text}}</span></div>
                  <div>Motif<span>{{motif?.type_name | titlecase}}</span></div>
                  <div class="flex justify-between items-center mt-3 p-2">
                      <button matStepperPrevious class="bg-blue-500 uppercase hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                          <mat-icon>navigate_before</mat-icon>
                          Retour
                      </button>
                      <button [disabled]="isDisabledNextButton" matStepperNext class="bg-blue-500 uppercase hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                          Suivant
                          <mat-icon>navigate_next</mat-icon>
                      </button>
                </div>
              </div>
              <div *ngIf="!errorNewPatient && !isAvailableSlot">
                  <div class="flex items-center p-4 mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                      <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                      <div class="ms-3 text-sm font-medium">
                          Créneaux non disponible <br />
                          Nous sommes désolés, ce créneau n'est plus disponible. Veuillez en choisir un autre.
                      </div>
                  </div>
                  <div class="mx-2 my-6 mb-6">
                      <h2 class="text-xl">Choisissez la date de consultation</h2>
                      <app-planning-list
                              *ngIf="dataPractice"
                              class="m-4"
                              [Practice]="dataPractice"
                              [reasons]="motif"
                              (emitSelectSlot)="selectSlot($event)"
                      >
                      </app-planning-list>
                  </div>
              </div>
              <div *ngIf="errorNewPatient">
                  <div class="flex items-center p-4 mx-2 text-yellow-800 bg-yellow-50 alert-message-style-warning" role="alert">
                      <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                      <div class="ms-3 text-sm font-medium">
                          Le praticien n'accepte pas de nouveaux patients.
                      </div>
                  </div>
                  <div>
                      <h2 class="text-xl mb-4 mt-4">Choisissez un autre praticien</h2>
                      Button to navigate to practices
                  </div>
              </div>
          </div>
      </mat-card>
  </div>
</div>
<div>
</div>
</div>