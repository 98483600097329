import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import {STATUS_OK} from "../../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  private _url: string;
  appointment: any= [];
  onAppointment = new BehaviorSubject([]);
  constructor(private _eaHttpAbstract: EaHttpAbstract) {}
  /**
* Récupère les rendez-vous d'un patient
*
*/
  getRdv(): Observable<any> {
      return this._eaHttpAbstract.post("/patient_area/list_appointments", {})
          .pipe(
              map((response: any)=>{
                  if(response.status === STATUS_OK){
                      this.appointment = response.data;
                      this.emitAppointment();
                  }
                  return response;
              })
          )
  }

  /**
   * Emettre les rdv
   */
   emitAppointment(){
    this.onAppointment.next(this.appointment)
  }
}
