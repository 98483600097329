import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Settings } from 'src/app/app.settings';
import { LocationService } from 'src/app/helpers/location.service';
import { environment } from 'src/environments/environment';
import {UtilsService} from "../../../helpers/utils.service";
import {SecurityService} from "../../../main/security/security.service";
@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
  styleUrls: ['./toolbar1.component.scss']
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  hide: boolean = false;
  constructor(
    public appService:AppService,
    public _locationService : LocationService,
    public _utilsService: UtilsService,
    public _securityService: SecurityService
  ) {
    let url = this.appService.getHostname();
    if(url.includes("https://www.tictacsante.ma") || url.includes("https://tictacsante.ma")){
      this.hide = true;
      this.setLocationCountry('Rabat', 'ma');
    } else if(url.includes("https://www.tictacsante.fr") || url.includes("https://tictacsante.fr")){
      this.hide = true;
      this.setLocationCountry('Paris', 'fr');
    } else {
      this.hide = false;
    }
    this.net_infos = this._locationService.networks;
    this.chosenLocation = JSON.parse(localStorage.getItem("chosenLocation"));
    this.country_c = this.chosenLocation?.country?
        this.appService.titleCaseWord(this.chosenLocation?.country)
        : this.appService.titleCaseWord(this.net_infos?.country_code);
    this._securityService.isConnectedSubject.subscribe(
        (connected) => {
          const dataUser = this._utilsService.getSession('userdata');
          this.isPatient = dataUser === null;
        });
    this._securityService.isConnectedSubject.subscribe(
      (connected) => {
      this.isPatient = !connected;
    });
  }
  
  public settings: Settings;
  public net_infos: any;
  public chosenLocation: any;
  public country_c: string;
  environment = environment
  open : boolean = false;
  isPatient : boolean = false;
  ngOnInit() {
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
  setLocationCountry(city: string, country: string): any{
    let dataNetwork = {
      city : city,
      country : country
    };
    localStorage.setItem('chosenLocation', JSON.stringify(dataNetwork));
    return dataNetwork;
  }

  changeLocation(country){
    const Data: any = {};
    Data.keyword = "";
    Data.city = "";
    Data.country = country;
    this.country_c = country;
    localStorage.removeItem("currentLocation");
    if(country?.toLowerCase() == 'fr'){
      let network = this.setLocationCountry('Paris', 'fr');
      this.country_c = this.appService.titleCaseWord(network?.country);
    } else {
      let network = this.setLocationCountry('Rabat', 'ma');
      this.country_c = this.appService.titleCaseWord(network?.country);
    }
    this.open = false;
    location.reload();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    if(!(event.target as Element).classList.contains('country')){      
      this.open = false
    }
  }
  currentFlagUrl(): string {
    let backgroundImageUrl = 'assets/images/flags/';
    switch (this.country_c) {
      case 'Ma': backgroundImageUrl+="ma.svg";
        break;
      case 'Fr': backgroundImageUrl+="fr.svg";
        break;
      default : backgroundImageUrl+="fr.svg";
        break;
    }
    return backgroundImageUrl;
  }

  clickCountry() {
    this.open=!this.open
  }
}