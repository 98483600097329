<div class="step-content">
    <div class="bg-white w-full md:max-w-4xl rounded-lg shadow">
        <div class="h-12 flex justify-between items-center border-b border-gray-200 m-4">
            <div >
                <div class="text-2xl font-bold text-gray-700">Choisissez un patient</div>
            </div>
        </div>
        <div class="px-6">
            <div *ngFor="let data of dataSource" class="flex justify-between items-center h-16 p-4 my-6  rounded-lg border border-gray-100 shadow-md">
                <div class="flex items-center">
                    <img
                            class="rounded-full h-12 w-12"
                            [src]="data.picture ? 'https://cdn.tictacsante.com/assets/images/doc/' + data.picture : 'https://cdn.tictacsante.com/assets/images/doc/Default.png'"
                            alt="Logo"
                    />
                    <div class="ml-2">
                        <div class="text-sm font-semibold text-gray-600">{{data?.first_name}} {{data?.last_name}}</div>
                        <div class="text-sm font-light text-gray-500">{{data?.category}}</div>
                    </div>
                </div>
                <div>
                    <mat-checkbox (change)="onChange(data?.reference)"
                                  [checked]="selected === data?.reference"
                                  (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div>
            <div class="flex justify-between items-center mt-3 p-2">
                <button matStepperPrevious class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                    <mat-icon>navigate_before</mat-icon>
                    <span class="uppercase">Retour</span>
                </button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button" (click)="confirmerRdv()">
                    <span class="uppercase mr-1">Confirmer le rendez-vous</span>
                    <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>